:root {
    --primary-color: #6e42ea;
}

.primary-bg{
    background: var(--primary-color) !important;
}

.w-btn{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    appearance: none;
    font-size: 1.6rem;
    font-weight: 600;
    padding: 1rem 2.8rem;
    border: .2rem solid;
    border-radius: .4rem;
    transition: background-color .3s, border-color .3s, color .3s;
    border-color: var(--primary-color);
    box-shadow: 0 1.4rem 3.8rem 0 rgba(110, 66, 234, .3);
    color: #fff;
    background-color: var(--primary-color);
}

.w-btn.big{
    font-size: 16px;
    padding: 12px 70px;
    font-weight: bold;
}

.w-btn.primary-bdr{
    color: #000;
    border: 2px solid var(--primary-color);
    transition: all .15s ease-in-out;
    box-shadow: none;
}

.w-btn.primary-bdr:hover{
    color: #fff;
    border: 2px solid transparent !important;
    background: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset
}

/*.web-header{*/
/*    background-color: rgba(255, 255, 255, .2);*/
/*    position: absolute;*/
/*    box-shadow: 0px 0px 2px 2px #827d7d;*/
/*}*/

.web-header .nav-link{
    padding: 0 5px;
}

.web-header .nav-link a{
    color: #000;
    cursor: pointer;
}

.web-header .nav-link a:hover{
    text-decoration: none;
}

.web-header a.phone{
    color: #000;
    font-weight: bold;
}

.home{
    background-image: url("../public/images/home-bg.png");
    background-repeat: no-repeat;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.home .icons{
    width: 30px;
    height: 30px;
}

.home .hero-section{
    background-image: url("../public/images/hero-section-bg.png");
    background-position: center -35px;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 50px);
    padding-left: 40px;
    padding-bottom: 100px;
}

.home .hero-section .buttons{
    margin-top: 60px;
}

.home .hero-section .main-heading{
    font-size: 46px;
    color: #070E27;
    font-weight: 800;
    line-height: 1.3;
    margin-bottom: 50px;
    margin-top: 150px;
    letter-spacing: 1px;
}

.home .sub-heading{
    font-size: 20px;
    color: #2B334D;
    font-weight: 600;
    margin: 0;
}

.home .simple-steps .heading{
    font-size: 22px;
    color: #292D34;
}

.home .simple-steps div{
    font-size: 20px;
}

.home .simple-steps div span:nth-child(1){
    font-weight: bold;
    font-size: 22px;
}

.carousel{
    height: 450px;
    border-radius: 20px;
}

.carousel img{
    height: 446px;
    width: 100%;
    border-radius: 20px;
}

.web-footer{
    padding: 80px 100px;
    background: #f6ffff;
}

.web-footer h4{
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 50px;
}

.web-footer a{
    font-size: 18px;
    color: #000000;
    display: block;
    margin-top: 10px;
}

.step-wrapper{
    margin-bottom: 50px;
    cursor: pointer;
}

.step-wrapper .number{
    background: #C1C0C1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    align-content: center;
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
}

.step-wrapper.active .number{
    background: var(--primary-color);
}

.step-wrapper .heading{
    color: #C1C0C1;
    font-size: 22px;
    font-weight: bold;
    margin: 0;
}

.step-wrapper.active .heading{
    color: #010101;
}

.step-wrapper .text{
    margin-top: 20px;
    color: #6F7A93;
}
