.header-wrapper{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    background: #fff;
}

a{
    color: unset;
}

a:hover{
    text-decoration: none;
    color: unset;
}

.logo .img{
    width: 50px;
    height: 50px;
}

.logo .title{
    text-transform: uppercase;
}

.logo .title span:nth-child(2), .gradient-text{
    background: -webkit-linear-gradient(#d05bdb, #6e42ea);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.logo .title{
    font-size: 25px;
    font-family: fantasy;
    letter-spacing: 2px;
    line-height: 1;
}

.logo .title-slogan{
    background: -webkit-linear-gradient(#d05bdb, #6e42ea);
    font-weight: bold;
    font-size: 7.7px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
}

.map-bg-img{
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    background-image: url("../public/images/map-bg.jpg");
    background-position: center;
    background-size: cover;
    opacity: .3;
}

.table, table {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important;
}

.input-bar, .button{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.button {
    background: linear-gradient(to right, #cd49c4, #9275ef);
    color: #fff !important;
    border: none;
    padding: 6px 8px !important;
}

.choose-ticket-wrapper{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
}

.choose-ticket-wrapper .p2{
    font-weight: bolder;
    color: #0061c5;
}

.choose-ticket-wrapper input{
    width: 35px;
    border-radius: 5px;
    font-size: 18px;
    padding: 5px;
    text-align: center;
}

.reserve-btn{
    border:  none !important;
    padding: 15.5px 40px !important;
    border-radius: 3px !important;
    font-weight: bold !important;
    background: #ffe11f !important;
    color: #000 !important;
}

.reserve-btn.disabled{
    background: #dfdfdf !important;
}

.parking-info-wrapper{
    width: 90%;
    margin: auto;
}

.parking-info-wrapper .box{
    background: #ededed;
    border-radius: 5px;
    padding: 0 !important;
}

.parking-info-wrapper .title{
    background: #e55f00;
    font-weight: bold;
    color: #ffffff;
    padding: 7px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.parking-info-wrapper .btn{
    background: #e55f00 !important;
    color: #ffffff !important;
    border: none !important;
    box-shadow: none !important;
}

.divider-text{
    text-align: center;
    position: relative;
    font-weight: bold;
}

.divider-text::before, .divider-text::after{
    content: "";
    width: calc(50% - 15px);
    height: 1px;
    background: #ccc;
    position: absolute;
    top: 50%;
    z-index: -1;
}

.divider-text::before{
    left: 0;
}

.divider-text::after{
    right: 0;
}

.map-icons-wrapper{
    position: absolute;
    top: 0px;
    left: 3px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    padding: 2px 0;
}

.map-icons-wrapper .icons:nth-child(1){
    padding: 3px;
    background: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.map-icons-wrapper .icons:nth-child(2){
    padding: 3px;
    background: #ffffff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.map-icons-wrapper .icon{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 60px;
    border-radius: 12px;
}

.map-icons-wrapper .icon:hover{
    background: #d5b513 !important;
}

.map-icons-wrapper .icon.active{
    background: #f6db27;
}

.map-icons-wrapper img{
    width: 32px;
}

.map-icons-wrapper .icon img.small{
    width: 25px;
}

.map-icons-wrapper .icon .rotate-svg{
    width: 42px;
}

.map-icons-wrapper .icon .circle{
    width: 30px;
    height: 30px;
    border: 5px solid #000000;
    border-radius: 50%;
}

.map-icons-wrapper .icon .rectangle{
    width: 35px;
    height: 25px;
    border: 4.5px solid #000000;
}

.polygon-icon{
    width: 27px;
    height: 27px;
    background-color: #000000;
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.popup-wrapper{
    padding: 10px 20px 20px 20px;
    border-radius: 5px;
    background: #ffffff;
    position: fixed;
    z-index: 3;
    left: 10px;
    bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: none;
}

.builder-map-bottom-bar, .bottom-table-grid{
    /*background: rgba(232, 217, 217, 0.5);*/
    /*padding: 5px 0;*/
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
}

.bottom-table-grid{
    background: #ffffff;
    z-index: 4;
    display: block;
    padding: 15px;
    max-height: 50vh;
    overflow: auto;
}

.bottom-table-grid table{
    box-shadow: none !important;
    width: 100%;
    border: 1px solid #000;
    border-collapse: collapse;
    overflow: scroll;
}

.bottom-table-grid table thead{
    position: sticky;
    top: 21px;
    background-color: #f9f9f9;
    z-index: 1;
}

.bottom-table-grid table tbody tr{
    cursor: pointer;
}

.bottom-table-grid table tbody tr:hover{
    background: rgba(54, 52, 52, 0.1);
}

.bottom-table-grid table td, .bottom-table-grid table th{
    border: 1px solid black;
    padding: 0 10px;
}

.popup-wrapper.show{
    display: block;
}

.popup-wrapper .content{
    max-width: 355px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popup-wrapper .content .buttons{
    margin-top: 70px;
}

.map-bottom-strip{
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 2;
    background: #ffffff;
    padding: 0 10px;
}

.gmnoprint{
    display: none;
}

.Toastify__toast-body{
    font-weight: bold;
    color: #000000;
    font-size: 15px;
}

.filter-locations-bar{
    position: absolute;
    left: 0;
    top: 66px;
    z-index: 1;
    background: #ffffff;
    width: 100%;
}

.filter-locations-bar .circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    margin-right: 5px;
}
